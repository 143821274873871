@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.svg-text,
.svg-subtext {
  font-family: "Poppins", sans-serif;
  fill: none;
  stroke: #ffffff;
  stroke-width: 1px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: draw 6s forwards ease-in-out, fill 4s forwards ease-in-out;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: currentColor;
  }
}

.svg-text:hover {
  fill: #ffcc29;
}

.name-text:hover {
  fill: #ffafcc;
}

.svg-subtext:hover {
  fill: #ffcc29;
}

.backdrop-blur {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.loader {
  border: 8px solid rgba(255, 255, 255, 0.2); /* Light white background */
  border-top: 8px solid white; /* Full white for the top part */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
